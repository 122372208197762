<template>
  <div style="overflow-x: auto;">
  <div class="page-body" >
    <div class="card msb">
      <msb>马上办</msb>
    </div>
    <div class="card cjd">
      <cjd>成绩单</cjd>
    </div>
    <div class="card xhd">
      <xhd>信号灯</xhd>
    </div>

    <!--

     -->
  </div>
  </div>
</template>

<script>
  import {
    getNewsPage
  } from '@/api/news/index'
  import cjd from './cjd.vue'
  import msb from './msb.vue'
  import xhd from './xhd.vue'
  export default {
    data() {
      return {
        tableLoading: false,
        page: {
          current: 1,
          size: 15,
          total: 0,
        },
        tableData: [],
        confirmLoading: false,
        formData: {
          name: '',
          sort: 0,
        },
        formRules: {
          name: {
            required: true,
            message: '请输入职务名称',
            trigger: 'blur'
          },
          sort: {
            required: true,
            message: '请输入排序序号',
            trigger: 'blur'
          },
        },
        dialogType: 'create',
      }
    },
    components: {
      cjd,
      msb,
      xhd
    },
    created() {
      debugger
    },
    methods: {
      fetchList() {
        this.tableLoading = true
        const params = {
          page: this.page.current,
        }
        getNewsPage(params)
          .then(res => {
            debugger
            this.tableData = res.data
            this.page.total = res.total
          })
          .finally(() => {
            this.tableLoading = false
          })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .default-container{
    min-width:auto;
    height:auto;
    min-height:auto;
  }
  .page-body {

    width: 3840px;
    font-size: 36px;
    height: 500px;
    background-color: aqua;
    background-image: url("~@/assets/images/dp/bj.png");
    display: flex;

    .card {
      height: 452px;
      margin: 24px;
      border-radius: 20px;
      background-color: white;

    }

    .cjd {
      flex:1;
      margin-left: 0px;
      margin-right: 0px;
    }
  }
</style>
