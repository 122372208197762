<template>
  <div class="card-main" style="">
    <div class="card-logo">
      <transition-group name="slide" tag="div">

        <div v-for="(item, index) in tableData" :key="item.id" class="item" v-if="index === currentIndex">
          <div class="card-header">
            <div class="logo-msb">
            </div>
            <div class="card-footer" style="flex:1">
              <div class="element"
                style="font-size: 38px;flex:3;font-weight: 500;color: #212121;line-height: 88px;overflow: hidden;">
                {{item.title}}
              </div>
              <div class="element" style="line-height: 88px;flex:1;text-align: right;">
                {{item.author}} &nbsp;{{item.created_at|formatTime}}&nbsp;发布
              </div>
            </div>

          </div>
          <!--  -->
          <div style="display: flex;">

            <div class="card-body img-bg" v-for="(img,idx) in item.images" :style="'background-image:url('+img+');'">

            </div>
            <!--<div class="card-body img-bg" style="background-image:url('http://nm.app.vc14.com/storage/upload/4nDSwf8693ulAGp9mcQHJ9RW5P4iZer3WQY98E38.jpg');">

            </div> -->

            <div class="card-body" style="flex:1;">
              <p class="font-body">
                <vue-seamless-scroll :data="item.content" :class-option="classOption" class="dep-list"
                  style="height: 290px;">
                  <div> {{ item.content | filterContent }}</div>
                </vue-seamless-scroll>

              </p>
            </div>
          </div>



        </div>
      </transition-group>

    </div>
  </div>

</template>

<script>
  import {
    getNewsPage2
  } from '@/api/news/index'

  export default {

    data() {
      return {
        classOption: {
          limitMoveNum: 3,
          step: 0.4,
          direction: 1,
        },
        tableData: [],
        page: {
          current: 1,
          size: 15,
          total: 0,
        },

        intervalTime: 5000, // 初始时间间隔，5秒
        currentIndex: 0, // 当前要显示的索引

      }
    },
    filters: {
      filterContent(value) {
        return value.replace(/<[^>]*>/g, '').replace(/&nbsp;/g, ' ');
      },
      formatTime(timestamp) {
        const now = new Date();
        const targetTime = new Date(timestamp);
        const diff = Math.abs(now - targetTime);
        const minute = 60 * 1000;
        const hour = minute * 60;
        const day = hour * 24;
        const week = day * 7;

        if (diff < minute) {
          return Math.floor(diff / 1000) + '秒' + (now > targetTime ? '前' : '后');
        } else if (diff < hour) {
          return Math.floor(diff / minute) + '分钟' + (now > targetTime ? '前' : '后');
        } else if (diff < day) {
          return Math.floor(diff / hour) + '小时' + (now > targetTime ? '前' : '后');
        } else if (diff < week) {
          return Math.floor(diff / day) + '天' + (now > targetTime ? '前' : '后');
        } else {
          const month = targetTime.getMonth() + 1;
          const dayOfMonth = targetTime.getDate();
          return month + '月' + dayOfMonth + '日';
        }
      }
    },
    created() {
      this.fetchList();

      setInterval(() => {
        this.currentIndex = (this.currentIndex + 1) % this.tableData.length;
      }, this.intervalTime);

      setInterval(() => {
        this.tableData = []
        this.fetchList();
        this.currentIndex = 0
      }, 300000); //获取数据
    },
    methods: {
      fetchList() {
        debugger
        this.tableLoading = true
        const params = {
          page: this.page.current,
        }
        getNewsPage2(params)
          .then(res => {
            this.tableData = res.data
            debugger
            this.page.total = res.total
          })
          .finally(() => {
            this.tableLoading = false
          })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .img-bg {

    background-position: center center;
    /* 图片居中 */
    background-size: cover;
    /* 图片裁剪 */
    background-repeat: no-repeat;
    /* 禁止重复 */
  }

  .element {
    white-space: nowrap;
    /* 防止换行 */
    overflow: hidden;
    /* 隐藏超出部分 */
    text-overflow: ellipsis;
    /* 显示省略号 */
  }

  .card-main {

    font-family: "PingFangSC-Regular", "苹方-简", "微软雅黑", 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding: 8px;

    .card-logo {
      background-image: url("~@/assets/images/dp/chengjidan.png");
      background-repeat: no-repeat;
    }

    .card-header {
      display: flex;

      .logo-msb {

        height: 178px;
        width: 178px;

        margin-right: 16px;

      }

      .dep-list {
        width: 580px;
        padding: 16px;
        padding-left: 0px;
        overflow: hidden;
        white-space: nowrap;
        position: relative;

        & span {}

        & ul {
          list-style: none;
          padding: 0;
          margin: 0 auto;

          &.ul-item {
            display: flex;

            .li-item {
              background-color: #FCEEEF;
              border-radius: 10px;
              padding: 16px;
              font-size: 30px;
              line-height: 42px;
              font-weight: 600;
              color: #F25642;
              margin-right: 16px;
              text-align: center;
            }
          }
        }
      }

    }

    .card-body {
      background-color: #FFF0F0;
      height: 327px;
      width: 550px;
      border-radius: 20px;
      margin: 8px;
      margin-top: -80px;
      padding: 16px;


      .font-body {
        font-size: 34px;
        color: #212121;
        line-height: 50px;
        overflow: hidden;
        display: -webkit-box;
        /* 将元素作为块级元素进行布局 */
        -webkit-line-clamp: 5;
        /* 限制最大显示行数为3行 */
        -webkit-box-orient: vertical;
        /* 垂直方向展开子元素 */
        text-overflow: ellipsis;
      }
    }

    .card-footer {
      display: flex;
      padding-left: 16px;
      padding-right: 16px;
      justify-content: space-between;
      color: #999;
      font-size: 26px;

    }
  }

  .slide-enter-active {
    transition: transform 0.1s, opacity 1s;
  }

  .slide-enter,
  .slide-leave-to

  /* .slide-leave-active in <2.1.8 */
    {
    opacity: 0;
    transform: translateY(100%);
  }
</style>
