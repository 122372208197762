<template>
  <div class="card-main">
    <div class="card-logo">
      <transition-group name="slide" tag="div">

        <div v-for="(item, index) in tableData" :key="item.id" class="item" v-if="index === currentIndex">
          <div class="card-header">
            <div class="logo-msb">
            </div>
            <div class="card-footer" style="flex:1">
              <div class="element"
                style="width: auto;font-size: 38px;color: #212121;font-weight: 500;line-height: 88px;text-align: center;">
                全厅任务实时情况
              </div>
            </div>

          </div>
          <!--  -->
          <div style="display: flex;flex-direction: column;margin-top: -80px;">
            <div class="tu-list" style="height: 130px;">
              <!-- <div style="background-color:rgba(255, 240, 240, 1);padding: 6px;">
1
              </div>
              <div style="background-color: rgba(255, 240, 240, 1);padding: 6px;">2</div>
              <div style="background-color: rgba(255, 240, 240, 1);padding: 6px;">3</div> -->
              <div style="background-color:rgba(255, 240, 240, 1);padding: 16px;">
                <p>进行中</p>
                <p>{{item.jxzAgendaCount}}</p>
              </div>
              <div style="background-color:rgba(255, 240, 240, 1);padding: 16px;">
                <p>已完成</p>
                <p>{{item.ywcAgendaCount | formatNumber}}</p>
              </div>
              <div style="background-color:rgba(255, 240, 240, 1);padding: 16px;">
                <p>逾期</p>
                <p>{{item.yyqAgendaCount}}</p>
              </div>
            </div>
            <div class="tu-list" style="height: 140px;padding-top: 16px;">
              <div>
                <p>任务发出</p>
                <p>{{item.agendaCount | formatNumber}}<small>项</small></p>
              </div>
              <div>
                <p>任务落实</p>
                <p>{{item.actionCount | formatNumber}}<small>件次</small></p>
              </div>
              <div>
                <p>工作日程</p>
                <p>{{item.scheduleCount | formatNumber}}</p>

              </div>
            </div>
            <div
              style="display: flex; height: 72px;background-color: rgba(255, 240, 240, 1); padding: 15px;border-radius: 20px;  font-size: 30px; color: rgba(255, 80, 56, 1)">

              <div class="text_30"> 任务逾期：</div>

              <vue-seamless-scroll :data="itemsDB.msbDeps" :class-option="classOption" style="overflow: hidden;width:379px;">
                 <!-- {{itemsDB.msbDeps}} -->
                 暂无
                  <!--  <div v-for="(item1, index2) in itemsDB.msbDeps" :key="index2">{{item1}} </div> -->
              </vue-seamless-scroll>
            </div>
          </div>



        </div>
      </transition-group>

    </div>
  </div>

</template>

<script>
  import {
    getXHD
  } from '@/api/news/index'

  export default {
    data() {
      return {
        classOption: {
          limitMoveNum: 3,
          step: 0.4,
          direction: 2,
        },
        tableData: [],
        page: {
          current: 1,
          size: 15,
          total: 0,
        },

        intervalTime: 20000, // 初始时间间隔，5秒
        currentIndex: 0, // 当前要显示的索引
        itemsDB:[{ msbDeps: [123] }]
      }
    },
    created() {
      this.fetchList()

      setInterval(() => {
        this.currentIndex = (this.currentIndex + 1) % this.tableData.length;
      }, this.intervalTime);

      setInterval(() => {
        this.fetchList()
        this.currentIndex = 0
      }, 300000); //获取数据
    },
    filters: {
       formatNumber(numStr) {
        const num = Number(numStr); // 将数字字符串转换为数字
            if (isNaN(num)) {
              return numStr; // 如果无法转换为数字，则原样返回
            }

          const length = String(num).length; // 获取数字字符串的长度
          console.log(length)
          if (length <= 4) {
            return numStr;
          } else if (length <= 8) {
            const numWan = Math.floor(num / 10000);
            const numYi = Math.floor(num / 100000000);
            if (numYi > 0) {
              return numYi + '亿';
            } else {
              return numWan + '万';
            }
          } else {
            return '999+';
          }
        }
    },
    methods: {
      fetchList() {
        this.tableLoading = true
        const params = {
          page: this.page.current,
        }
        getXHD(params)
          .then(res => {
            this.tableData = []
            this.tableData.push(res)
          })
          .finally(() => {
            this.tableLoading = false
          })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .tu-list {
    display: flex;
    gap: 16px;
    padding-left: 16px;
    padding-right: 16px;

    &>div {
      flex: 1;
      text-align: center;
      background-color: #fff;

      border-radius: 20px;
    }

    .kuai {
      background-color: #fff;
    }
  }

  .img-bg {

    background-position: center center;
    /* 图片居中 */
    background-size: cover;
    /* 图片裁剪 */
    background-repeat: no-repeat;
    /* 禁止重复 */
  }

  .element {
    white-space: nowrap;
    /* 防止换行 */
    overflow: hidden;
    /* 隐藏超出部分 */
    text-overflow: ellipsis;
    /* 显示省略号 */
  }

  .card-main {

    font-family: 'PingFangSC', 'PingFang SC', "苹方-简", "微软雅黑", 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding: 8px;

    .card-logo {
      background-image: url("~@/assets/images/dp/xinhaodeng.png");
      background-repeat: no-repeat;
    }

    .card-header {
      display: flex;

      .logo-msb {
        height: 178px;
        width: 178px;
        margin-right: 16px;
      }
    }


  }

  .slide-enter-active {
    transition: transform 0.1s, opacity 1s;
  }

  .slide-enter,
  .slide-leave-to

  /* .slide-leave-active in <2.1.8 */
    {
    opacity: 0;
    transform: translateY(100%);
  }
</style>
